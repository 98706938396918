<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlSjhszSearch " :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"  :dynamic='true'></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" ></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          TreeIds:[],//选中集合
          page: 1,
          limit: 50,
          queryType:'0',
          queryKey:'SurfaceNum',
          queryValue:'',
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        mainMultipleSelection:[],//多选数据
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data,2)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        if(this.mainMultipleSelection.length <= 0){
          this.$message({
            message: `请选择一条数据进行操作！`,
            type: 'error'
          })
          return
        }
        console.log('you click:' + domId)
        switch (domId) {
          case 'restore'://恢复
            this.handleRestore();//恢复
            break
          
          case 'delete'://销毁
            this.handleDelete()
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.sjhszGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //销毁
      handleDelete() { 
        this.$confirm(`确定要销毁数据吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = []
          this.mainMultipleSelection.forEach(i=>{
            data.push(i.id)
          })
          basicDataApi.sjhszDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
            }else{
              this.$notify({
                message: response.result,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      //恢复
      handleRestore(){
        this.$confirm(`确定要恢复数据吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = []
          this.mainMultipleSelection.forEach(i=>{
            data.push(i.id)
          })
          basicDataApi.sjhszRestore(data).then(response => {
            if(response.code == 200){
              if(response.status == 200){//全成功
                this.$notify({
                  message: response.message,
                  type: 'success',
                  duration: 2000
                });
                this.getList()
              }else if(response.status == 500){//全失败
                this.$notify({
                  message: response.result,
                  type: 'warning',
                  duration: 2000
                });
              }else{
                this.$notify({//部分成功
                  message:  response.result,
                  type: 'warning',
                  duration: 2000
                });
                this.getList()
              }
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
